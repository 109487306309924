<template>
  <div class="page-content person">
    <div class="page-form content">
      <Form
        :ref="`form-person`"
        use-cache
        form-key="form-person"
        :defaultForm.sync="form"
        :fields="fields"
        :defaultText.sync="fieldTexts"
      >
        <van-field
          slot="field-custom-spouseName"
          v-model.trim="form.spouseName"
          label="配偶姓名"
          placeholder="上传识别"
          :rules="required"
          required
          disabled
          input-align="right"
        >
          <template #right-icon>
            <ImgIcon :size="28" color="#4191f9" @click="openIdcarOcr" />
          </template>
        </van-field>
      </Form>
    </div>
    <div class="page-btn btns">
      <van-button type="info" size="small" @click="submitFn">提交</van-button>
    </div>
  </div>
</template>
<script>
import { getSomeEnums } from "@/api/enums";
import { submitGuaInfo } from "@/api/apply";
export default {
  components: {
    Form: () => import("@/components/Form/Index")
  },
  data() {
    return {
      required: [
        {
          required: true
        }
      ],
      enums: {},
      form: {},
      signKey: "",
      fieldTexts: {}
    };
  },
  computed: {
    fields() {
      return [
        {
          type: "select",
          label: "与申请人关系",
          required: true,
          key: "relation",
          enums: this.enums?.relation || [],
          change: (item) => {
            this.form.relationName = item.text;
          }
        },
        {
          type: "text",
          label: "电子邮箱",
          required: true,
          key: "email",
          validator: (v) => {
            const reg =
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (v && !reg.test(v)) throw "请输入正确的电子邮箱";
            else return true;
          }
        },
        {
          type: "text",
          label: "微信号",
          required: true,
          key: "wechatId"
        },
        {
          type: "text",
          label: "工作单位名称",
          required: true,
          key: "companyName"
        },
        {
          type: "text",
          label: "单位电话",
          required: true,
          key: "companyPhone"
        },
        {
          type: "number",
          label: "税后年薪(万元)",
          required: true,
          key: "yearIncome"
        },
        {
          type: "number",
          label: "其他税后年薪(万元)",
          required: true,
          key: "otherIncome"
        },
        {
          type: "region",
          label: "居住地址",
          required: true,
          keys: ["resideProvinceCode", "resideCityCode", "resideAreaCode"],
          namesKey: ["resideProvinceName", "resideCityName", "resideAreaName"],
          key: "ff8"
        },
        {
          type: "text",
          label: "详细地址",
          required: true,
          placeholder: "请输入详细地址(需具体到门牌号)",
          key: "resideAddress"
        },
        {
          type: "region",
          label: "公司地址",
          keys: ["companyProvinceCode", "companyCityCode", "companyAreaCode"],
          namesKey: [
            "companyProvinceName",
            "companyCityName",
            "companyAreaName"
          ],
          required: true,
          key: "ff10"
        },
        {
          type: "text",
          label: "详细地址",
          required: true,
          placeholder: "请输入详细地址(需具体到门牌号)",
          key: "companyAddress"
        },
        {
          type: "upload",
          label: "收入证明",
          limit: 1,
          required: true,
          key: "attaches"
        }
      ];
    }
  },
  created() {
    let { signKey } = this.$route.query;
    if (signKey.includes("+")) signKey = encodeURIComponent(signKey);
    this.signKey = signKey || "";
  },
  mounted() {
    this.getAllEnums();
  },
  methods: {
    async getAllEnums() {
      try {
        const [d1] = await Promise.all([getSomeEnums("relation")]);
        this.$set(this.enums, "relation", d1);
      } catch (error) {
        this.$errMsg(error);
      }
    },

    async submitFn() {
      try {
        if (!this.signKey) throw "路径秘钥错误!";
        const infodom = this.$refs["form-person"];
        if (!infodom) throw "页面渲染错误";

        await infodom.validate();
        let params = this.$deepClone(this.form);
        const url = params.attaches;
        params.attaches = [];
        // 后端接受参数为数组，但是仅限一张， 6！
        params.attaches[0] = url;
        params.signKey = this.signKey;
        this.$toast.loading({
          message: "处理中...",
          forbidClick: true,
          duration: 0
        });
        await submitGuaInfo(params);
        infodom.clearCacheFormData();
        this.$toast.success("保存成功");
        this.$router.push(`/guarantee?signKey=${this.signKey}`);
      } catch (error) {
        this.$errMsg(error);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@import "../detail/input-style.scss";
.person {
  .btns {
    .van-button {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
